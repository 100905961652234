import Profile from "@/models/Profile.model.js";
import {
  setSessionStorageDisableTeleconsultation,
  setSessionStorageDisableSideBar,
} from "@/store/sessionStorage.js";
import Store from "@/store";
import { userApi, getErrorKeysFrom } from "@/services/api";
import { hideCookieBar } from "@/utils/cookies";

/**
 * Handles navigation using a common redirection route:
 * Example: <baseUrl>/navigate?samlSiteName=mindmaze&patientSiteId=augustina&context=dashboard&disableSidebar=true
 * --> /patient/<patientUsername>/dashboard (without sidebar)
 */

/// Patient convenience route map for therapists
const defaultRouteMap = "Therapist/Patient";
const PatientRouteMap = Object.freeze({
  dashboard: "Therapist/Patient/Dashboard",
  plan: "Therapist/Patient/Plan",
  teleconsultation: "Therapist/Patient/Teleconsultation",
  monitor: "Therapist/Patient/Monitor",
  review: "Therapist/Patient/Review",
  assessment: "Therapist/Patient/Assessment",
  epro: "Therapist/Patient/EPRO",
  profile: "Therapist/Patient/Profile",
});

/**
 * Stores the current navigation route for after the authentication process
 * @param {Route} to current route
 */
function rememberNavigationRoute(to) {
  const query = JSON.stringify(to.query || {});
  try {
    window.sessionStorage.setItem("mm_navigate_query", query);
  } catch {
    // Can happen when testing repeatedly in private mode and when refusing cookies
    // At least, the user gets some information about it
    console.error(
      "Cannot write previous navigation query. The navigation redirection will not work after login"
    );
  }
}

/**
 * Retreives the current navigation route after the authentication process if any
 */
export function popPreviousNavigationRoute() {
  const queryStr = window.sessionStorage.getItem("mm_navigate_query");

  // Forget it once obtained
  window.sessionStorage.removeItem("mm_navigate_query");

  const route = { name: "Navigation", query: {} };
  try {
    const query = JSON.parse(queryStr);
    if (query) {
      route.query = query;
      return route;
    } else {
      return {}; // invalid route
    }
  } catch {
    return {}; // invalid route
  }
}

/**
 * Redirect the user to the SAML idP authentication page
 * corresponding to the given saml site name
 * @param {Route} to current route
 * @param {NavigationGuardNext<*>} next route redirection callback
 * @returns callback with actual redirection in case of error.
 * Will overwrite the URL to go to the idP if all goes well
 */
async function samlAuth(to, next) {
  const query = to.query || {};

  // Don't process an autologin when errors are happening
  const errorKey = query.errorKey;
  const technicalErrorCode = query.technicalErrorCode;
  if (errorKey || technicalErrorCode) {
    // Forward to an error page, keeping the query parameters
    return next({ name: "loginsaml" });
  }

  // Get the idp url and attempt to go there
  const samlSiteName = query.samlSiteName;
  await userApi
    .get(`auth/saml/${samlSiteName}`)
    .then((response) => {
      if (response.status == "200") {
        const idpURL = response.data?.result?.redirectionURL || "";
        if (idpURL) {
          // Go to the idP URL in the same window
          window.location = idpURL;
        } else {
          // Should never happen
          to.query.errorKey = "errors.site.emptyName";
          return next({ name: "loginsaml" });
        }
      } else {
        to.query.errorKey = "errors.site.noMatchingSite";
        to.query.technicalErrorCode = response.status;
        return next({ name: "loginsaml" });
      }
    })
    .catch((error) => {
      to.query.errorKey = getErrorKeysFrom(error)[0] || "";
      return next({ name: "loginsaml" });
    });
}

/**
 * Handle navigate parameters to return the route
 * corresponding to the given patient site id in the route
 * @param {Route} to current route
 * @param {NavigationGuardNext<*>} next route redirection callback
 * @returns callback with actual redirection
 */
async function computePatientManagementPageRoute(to, next) {
  // The default route ("home"), without any query parameters
  const defaultPage = { name: "home", query: {} };

  const query = to.query || {};
  // Handle redirection to the patient page
  const routeName = PatientRouteMap[query.context] || defaultRouteMap;

  if (routeName === "home") {
    return next(defaultPage);
  }

  const patientSiteId = query.patientSiteId || "";
  const disableSidebar = query.disableSidebar;
  const disableTeleconsultation = query.disableTeleconsultation;

  const redirection = {
    name: routeName,
    query: {}, // clean query when redirecting
  };

  // Handle parameters
  if (disableSidebar != undefined) {
    setSessionStorageDisableSideBar(disableSidebar);
  }
  if (disableTeleconsultation != undefined) {
    setSessionStorageDisableTeleconsultation(disableTeleconsultation);
  }

  if (patientSiteId) {
    // The redirection is valid, we are about to go there
    const patientRequest = await Profile.getPatients(null, true, false, false);
    const patients = patientRequest?.response?.data?.result || [];

    // N.B.: Case insensitive comparison based on characters
    const patient = patients.find(
      (p) =>
        p?.patientSiteId.localeCompare(patientSiteId, undefined, {
          sensitivity: "base",
        }) === 0 || false
    );

    if (patient) {
      const patientUsername = patient.userName;
      redirection.params = { username: patientUsername };
      return next(redirection);
    } else {
      // The patient doesn't exist, redirect to the patient not found page
      return next({
        name: "Therapist/AddPatient",
        query: { patientSiteId: patientSiteId, nextRoute: routeName },
      });
    }
  }

  // Redirecting to the default route ("home")
  return next(defaultPage);
}

export default [
  {
    path: "/navigate",
    name: "Navigation",
    // N.B.: redirect() doesn't support async according to:
    // https://github.com/vuejs/vue-router/issues/2729 and others
    // therefore we use beforeEnter()
    beforeEnter: async (to, from, next) => {
      // Hide the cookie bar in the navigate mode for the current session/page if needed
      hideCookieBar();
      await Store.dispatch("Auth/setHideCookieBar", true);

      if (Store.getters["Auth/hasAccessToken"]) {
        // If everything is setup, compute the route to the patient page
        return await computePatientManagementPageRoute(to, next);
      } else {
        rememberNavigationRoute(to);

        // Determine if the authentication process can use SAML
        const query = to.query || {};
        if (query.samlSiteName) {
          // SAML auth
          return await samlAuth(to, next);
        } else {
          // Basic AUTH
          return next({ name: "login", query: {} });
        }
      }
    },
  },
];
