<template>
  <div :class="{ error: hasErrors }">
    <slot />
    <div class="errors" v-if="hasErrors">
      <div
        class="error form-required"
        v-for="error in activeErrorMessages"
        :key="error"
      >
        {{ $t(`${error}`) }}
      </div>
    </div>
  </div>
</template>

<script>
import { singleErrorExtractorMixin } from "vuelidate-error-extractor";

export default {
  mixins: [singleErrorExtractorMixin],
};
</script>
