import Cookies from "js-cookie";

const cookiesAcceptedName = "companion_cookies_accepted";
const tokenCookieName = "mm_token"; // N.B.: Name also used by the MMGO
const cookieBarState = "companion_cookie_bar";

const CookieStatus = Object.freeze({
  NotSet: "NotSet",
  Accepted: "Accepted",
  Declined: "Declined",
});

export function areCookiesAccepted() {
  return localStorage.getItem(cookiesAcceptedName) === CookieStatus.Accepted;
}

export function acceptCookies() {
  localStorage.setItem(cookiesAcceptedName, CookieStatus.Accepted);
}

export function declineCookies() {
  localStorage.setItem(cookiesAcceptedName, CookieStatus.Declined);
}

/**
 * Hide the cookie bar, e.g. when in navigate mode
 */
export function hideCookieBar() {
  sessionStorage.setItem(cookieBarState, "hide");
}

export function isCookieBarHidden() {
  return sessionStorage.getItem(cookieBarState) === "hide";
}

/**
 * Set the access token cookie value
 * The access token cookie itself will expire after 30 days if not set as a session cookie
 * See: https://developer.mozilla.org/fr/docs/Web/HTTP/Headers/Set-Cookie/SameSite
 * We flag our cookies as secure and permit their use in third party apps.
 * @param token {string}
 */

export function setAccessTokenCookie(token, sessionCookie = true) {
  const expirationInDaysFromNow = 30;
  Cookies.set(tokenCookieName, token, {
    sameSite: "Strict",
    secure: true,
    expires: sessionCookie ? undefined : expirationInDaysFromNow,
  });
}

export function getAccessTokenCookie() {
  return Cookies.get(tokenCookieName);
}

export function removeAccessTokenCookie() {
  Cookies.remove(tokenCookieName, { sameSite: "Strict", secure: true });
}

// Telemetry cookies
export function setAppInsightCookie(name, value) {
  Cookies.set(name, value, { sameSite: "Strict", secure: true });
}

export function getAppInsightCookie(name) {
  return Cookies.get(name);
}

export function removeAppInsightCookie(name) {
  Cookies.remove(name, { sameSite: "Strict", secure: true });
}
